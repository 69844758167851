import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import { Fade } from 'react-reveal';
import picPek from '../assets/images/picPek.png';
import picJonah from '../assets/images/picJonah.png';
import picBrandon from '../assets/images/picBrandon.png';
import picGlen from '../assets/images/picGlen.png';
// import picJohan from '../assets/images/picJohan.png';
import picNingWei from '../assets/images/picNingWei.png'
import picBen from '../assets/images/picBen.png';

const OurTeam = (props) => (
	<Layout>
		<Helmet>
			<title>Our Team</title>
			<meta name='description' content='Left Sidebar Page' />
		</Helmet>
		<Fade duration={2500}>
			<div id='main' className='wrapper style1'>
				<div className='container'>
					<header className='major'>
						<h2>Our Team</h2>
						<p>Why we are good at what we do.</p>
					</header>

					<div className='row gtr-150'>
						<div className='col-4 col-12-medium' style={{marginLeft: `15vw`}}>
							<img
								alt=''
								src={picPek}
								style={{
									maxWidth: `300px`,

								}}
							/>
							<h3>Pek Yun Ning</h3>
							<h4 style={{ color: `darkgray` }}>CHAIRMAN, CEO &amp; FOUNDER</h4>
							<p>
								<b>
									Thanks to Pek Yun Ning’s leadership, the company received the Singapore Trusted Enterprise Award in January 2023. It also won the Best Companies Award at the Dubai Food 2.0 Conference 2022 and the Singapore Prestige Class Award 2021/2022 for business growth, innovative solutions, and foresight. 

Pek was recognised as one of the Ten Outstanding Young Persons of the World Awardees (Singapore) in February 2023 for her leadership in entrepreneurship, the environment, and politics. She was also named one of the Top 10 Best Women Leaders Under 30 from Asia in 2022 for her entrepreneurial spirit, ambition, and drive for growth. In the same year, she received the Women Eco Game Changer Award – Food & Clean Water Technology.

The data scientist, engineer, and investment management professional earned her Master's in Technology Entrepreneurship at SUTD and her International Diploma in Entrepreneurship from the University of California, Berkeley. She did her summer research at the Massachusetts Institute of Technology, before embarking on a two-year rotational programme at an international asset management company with more than S$1 trillion assets under management.
								</b>
							</p>
						</div>
						<div className='col-4 col-12-medium'>
							<img
								alt=''
								src={picJonah}
								style={{
									maxWidth: `300px`,
								}}
							/>
							<h3>Jonah Crawford</h3>
							<h4 style={{ color: `darkgray` }}>CTO &amp; CO-FOUNDER</h4>
							<p>
								<b>
									Jonah's multi-decade career in IoT, embedded control systems,
									software engineering and mechatronics. Having been a consultant and technology provider to multiple carbon initiatives, he brings to SGP Foods a growing and supported technology stack upon which the company is building next-generation carbon solutions. Crawford leads SGP Foods' full-time engineering team, which taps on diverse technologies and visionary tech integration.
								</b>{' '}
							</p>
						</div>
					</div>
					<hr />
				</div>
			</div>
		</Fade>
	</Layout>
);

export default OurTeam;
